import TechnologyCarousel from "views/cupra-campaign/carousel/technology-carousel";
import { getTextContent } from "../helpers";

import "./CarouselElement.css";
import DesignCarousel from "views/cupra-campaign/carousel/design-carousel";

function CarouselElement({ element, setActiveSlide, creativeState = {} }) {
  const { style, targetSlide, config = {} } = element;

  const { state = {}, instance } = config;

  const { disabled: stateDisabled } = state;

  const disabled = stateDisabled !== undefined && !creativeState[stateDisabled];

  const text = getTextContent(element);
  let CarouselContent = null;

  function onClick(targetSlide) {
    if (targetSlide) {
      return function () {
        setActiveSlide(targetSlide);
      };
    }
  }

  switch (instance) {
    case "tech":
      CarouselContent = <TechnologyCarousel />;
      break;
    case "design":
      CarouselContent = <DesignCarousel />;
      break;
    default:
      break;
  }

  return <div>{CarouselContent}</div>;
}

export default CarouselElement;
